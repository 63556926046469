<template>
  <div class="companyDetail">
    <div class="companyName" @click="to_companyDetai()">
      <span>{{ info.name_zh || info.name_en || "-" }}</span>
    </div>
    <div>公司规模：{{ info.scale | staffSizeFormat("zh") }}</div>
    <div>所属行业：{{ info.industry | industryFormat("zh") | textFormat }}</div>
    <div>
      所在地区：{{
        info.company_country
          | countryCityFormat(
            info.company_region_en,
            info.company_region_zh,
            LOCALE
          )
      }}
    </div>
    <div class="clearfix href">
      <div class="fl">公司主页：</div>
      <div
        class="cursor textOverflow fl"
        :class="{ mainColor: info.website }"
        :title="info.website"
        @click="toWebsite"
      >
        {{ info.website | textFormat }}
      </div>
    </div>
  </div>
</template>

<script>
import viewPostItem from "~scr/components/booth/viewPostItem";

export default {
  name: "viewPost",
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    viewPostItem,
  },

  methods: {
    to_companyDetai() {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: this.info.company_id,
          }),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    toWebsite() {
      if (this.info.website) window.open(this.info.website);
    },
  },
};
</script>

<style scoped lang="less">
.companyDetail {
  width: 300px;
  padding: 12px 20px 16px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-top: 16px;
  .companyName {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500px;
    & > span:hover {
      color: #0083f6;
    }
  }
  .href {
    .mainColor {
      width: 190px;
    }
  }
  div {
    line-height: 40px;
  }
}
</style>
